import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetTeacherList } from '@/api/ceshi_xiugai/Myteacher';
import { GetAgentList } from '@/api/ceshi_xiugai2/myproject';
export default {
  name: 'DisplaySettings',
  props: {
    displayArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    displayValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    defaultValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    cname: {
      type: String,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      value: JSON.parse(JSON.stringify(this.displayValue)),
      name: this.cname,
      dialogTableVisible: false,
      draging: null,
      fullscreenLoading: false,
      page: 1,
      pagesize: 15,
      shizi: ['组织部门', '姓名', '跟进次数', '性别', '手机号', '主要社会职务', '简介', '研究领域', '学员满意度', '午休', '创建人', '状态', '课酬金额', '生日', '师资分类'],
      qiye: ['序号', '置顶', '名称', '生日', '来源', '公司等级', '合作级别', '花名', '最后跟进日期', '联系人', '手机号', '公司规模', '性别', '创建时间', '跟进次数', '创建人']
    };
  },
  watch: {
    value: function value(newV) {
      this.$emit('update:displayValue', newV);
    },
    dialogTableVisible: function dialogTableVisible(newV) {
      var _this = this;
      if (!newV) {
        this.name;
        if (this.name == '师资管理') {
          GetTeacherList({
            page: this.page,
            pagesize: this.pagesize,
            field_list: this.displayValue
          }).then(function (respomse) {
            localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
          });
        } else {
          GetAgentList({
            page: this.page,
            pagesize: this.pagesize,
            field_list: this.value
          }).then(function (respomse) {
            localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
          });
        }
      }
    }
  },
  mounted: function mounted() {
    this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
  },
  methods: {
    handleClose: function handleClose(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },
    huanyuan: function huanyuan() {
      if (this.name == '师资管理') {
        this.value = JSON.parse(JSON.stringify(this.shizi));
      } else {
        this.value = JSON.parse(JSON.stringify(this.qiye));
      }
    },
    showEswt: function showEswt() {
      var _this2 = this;
      this.fullscreenLoading = true;
      if (this.name == '师资管理') {
        GetTeacherList({
          page: this.page,
          pagesize: this.pagesize
        }).then(function (respomse) {
          _this2.value = respomse.data.fields;
          _this2.dialogTableVisible = true;
          _this2.fullscreenLoading = false;
        });
      } else {
        GetAgentList({
          page: this.page,
          pagesize: this.pagesize
        }).then(function (respomse) {
          _this2.value = respomse.data.fields;
          _this2.dialogTableVisible = true;
          _this2.fullscreenLoading = false;
        });
      }
    },
    // 开始拖放
    ondragstart: function ondragstart(event) {
      event.dataTransfer.setData('te', event.target.innerText); //设置拖放数据与类型，不能使用text，firefox会打开新tab
      this.draging = event.target;
    },
    ondragover: function ondragover(event) {
      event.preventDefault();
      var target = event.target;
      // 判断是不是span在进行拖动，防止将整个拖放容器拖动
      if (target.nodeName === 'SPAN' && target !== this.draging) {
        if (this._index(this.draging) < this._index(target)) {
          target.parentNode.insertBefore(this.draging, target.nextSibling);
        } else {
          target.parentNode.insertBefore(this.draging, target);
        }
      }
    },
    // 拖放结束刷新列表
    ondragend: function ondragend(event) {
      var nodeData = [];
      var target = event.target;
      var nodeArr = target.parentNode.children;
      // 获取排序后的数据
      if (target.nodeName === 'SPAN') {
        for (var i = 0; i < nodeArr.length; i++) {
          nodeData.push(nodeArr[i].innerText);
        }
        this.value = nodeData;
      }
    },
    // 拖放时进行排序
    _index: function _index(el) {
      var index = 0;
      if (!el || !el.parentNode) {
        return -1;
      }
      while (el && (el = el.previousElementSibling)) {
        index++;
      }
      return index;
    }
  }
};